import * as React from "react"
import { MainLayout } from "../layouts/main.layout"
import Seo from "../components/seo"

const NotFoundPage = (props) => (
  <MainLayout location={props.location}>
    <Seo
      title='404: Page not found'
      // location={window.location.pathname}
    />
    <div>
      <h1>Page not found</h1>
      <p>Oops! Sorry, we could not find the page.</p>
      {/*<img src={PageNotFound} alt="Page not found" />*/}
    </div>

  </MainLayout>
)

export default NotFoundPage
